import { gql } from '@apollo/client';

export const USERS = gql`
  query usersAdmin($filters: AdminSearchInput) {
    usersAdmin(filters: $filters) {
      count
      users {
        billable
        id
        displayName
        email
        jobTitle
        department {
          name
        }
        isActive
        isIntern
      }
    }
  }
`;

export const EMPLOYEE_DETAILS = gql`
  query employeeDetails($id: ID!) {
    employeeDetails(id: $id) {
      id
      firstName
      lastName
      displayName
      gender
      billable
      profileImage
      jobTitle
      department {
        name
        id
      }
      reportingManager {
        displayName
        id
      }
      employeeNumber
      dateOfBirth
      dateOfJoining
      workEmail
      personalEmail
      mobileNo
      integrations {
        referenceId
        referenceName
      }
      tags
      skills {
        skillName
        skillValue
      }
      roleId
      isIntern
      internNumber
      isActive
      permissionRole {
        id
        name
        key
        isDefault
        createdAt
        updatedAt
      }
    }
  }
`;

export const DEPARTMENTS = gql`
  query departments($search: String, $filters: DepartmentFiltersInput) {
    departments(search: $search, filters: $filters) {
      count
      departments {
        id
        name
      }
    }
  }
`;

export const DESIGNATIONS = gql`
  query designations($searchString: String, $filters: DesignationFiltersInput) {
    designations(searchString: $searchString, filters: $filters) {
      count
      designations {
        jobTitle
      }
    }
  }
`;

export const ALL_TAGS = gql`
  query searchTags($searchString: String, $filters: FiltersInput) {
    searchTags(searchString: $searchString, filters: $filters) {
      count
      tags {
        id
        name
      }
    }
  }
`;

export const SKILL_SET = gql`
  query Skills($filters: SkillFilterInput!) {
    skills(filters: $filters) {
      count
      skills {
        id
        name
      }
    }
  }
`;

export const USER_LEVEL = gql`
  query levels {
    levels {
      count
      levels {
        id
        name
        description
      }
    }
  }
`;

export const CHECK_AUTH_FOR_CHAT = gql`
  query checkAuthForChat($input: UniqueIdInput) {
    checkAuthForChat(input: $input) {
      isAuthenticatedForChat
    }
  }
`;

export const GET_CHAT_AUTH_URL = gql`
  query getChatAuthUrl($input: UniqueIdInput) {
    getChatAuthUrl(input: $input) {
      url
    }
  }
`;

export const CHECK_AUTH_FOR_CALENDER = gql`
  query checkAuthForCalendar($input: UniqueIdInput) {
    checkAuthForCalendar(input: $input) {
      isAuthenticatedForCalendar
    }
  }
`;

export const GET_CALENDER_AUTH_URL = gql`
  query getCalendarAuthUrl($input: GetCalendarAuthUrlInput!) {
    getCalendarAuthUrl(input: $input) {
      url
    }
  }
`;
