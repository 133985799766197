import { useLazyQuery } from '@apollo/client';
import { Select } from 'antd';
import { debounce, get, map, trim, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT } from '../common/constants';

const { Option } = Select;
let searchDebounce = null;

const CommonSelect = (props) => {
  const {
    placeholder = 'Search',
    query,
    fetchPolicy,
    variables = {},
    responsePath,
    valuePath,
    labelPath,
    showSearch,
    conditionToCheckBeforeQuery = true,
    isDataDependent = [],
    customOptions = [],
    useEffectDeps = [],
    inputRef = null,
    getApiData,
    ...rest
  } = props;
  const [listData, setListData] = useState([...customOptions] || []);
  const [hasMore, setHasMore] = useState(true);
  const [skipData, setSkipData] = useState(0);
  const [searchText, setSearchText] = useState('');

  const [fetchListData, { loading }] = useLazyQuery(query, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const data = get(res, responsePath, []);
      const count = data?.length;
      const records = [...listData, ...data];
      setListData(uniqBy([...customOptions, ...records], 'id'));
      setHasMore(count >= LIMIT);
      if (getApiData) {
        getApiData(records);
      }
    },
    onError() {},
  });

  useEffect(() => {
    if (isDataDependent) {
      setListData([]);
      setSkipData(0);
    }
  }, isDataDependent);

  useEffect(() => {
    if (conditionToCheckBeforeQuery) {
      fetchListData({
        fetchPolicy,
        variables: {
          ...variables,
          filters: {
            ...(variables?.filters || {}),
            skip: 0,
            limit: LIMIT,
          },
        },
      });
    }
  }, useEffectDeps);

  const searchQuery = (search) => {
    setSearchText(trim(search));
    fetchListData({
      fetchPolicy: 'network-only',
      variables: {
        ...variables,
        searchString: trim(search),
        filters: {
          ...(variables?.filters || {}),
          skip: skipData,
          limit: 10,
        },
      },
    });
  };

  const handleSearch = (value) => {
    setSkipData(0);
    if (searchDebounce) {
      searchDebounce.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(searchQuery, 500);
    searchDebounce(trim(value));
  };

  const handleClear = () => {
    if (searchText) {
      searchQuery();
    }
  };
  const handleScroll = async (event) => {
    const { target } = event;
    const { scrollTop, clientHeight, scrollHeight } = target || {};
    const scrolledToBottom = scrollTop + clientHeight >= scrollHeight - 5;
    if (scrolledToBottom && hasMore) {
      const newSkip = skipData + LIMIT;
      await fetchListData({
        variables: {
          ...variables,
          filters: {
            ...variables?.filters,
            skip: newSkip,
            limit: LIMIT,
          },
        },
      });
      setSkipData(newSkip);
    }
  };

  return (
    <>
      <Select
        ref={inputRef}
        placeholder={placeholder}
        loading={loading}
        showSearch={showSearch}
        onSearch={showSearch && handleSearch}
        onClear={handleClear}
        onBlur={handleClear}
        onPopupScroll={handleScroll}
        filterOption={(inputValue, option) =>
          option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
        }
        {...rest}
      >
        <>
          {map(uniqBy([...listData], valuePath), (item) => {
            const optionValue = get(item, valuePath);
            const optionLabel = get(item, labelPath);
            return (
              <Option
                key={`option-key:${optionValue}`}
                value={optionValue}
                label={optionLabel}
              >
                {optionLabel}
              </Option>
            );
          })}
        </>
      </Select>
    </>
  );
};

export default CommonSelect;
