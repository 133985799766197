/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  AUTH: '/auth',
  GUEST: '/guest',
  LOGIN: '/login',
  RESUME: '/resume',
  VERIFY_OTP: '/verify-otp',
  AUTHENTICATION: '/authentication',
  VIEW_PROJECT: '/projects/:projectId',
  ADD_PROJECT: '/projects/add',
  EDIT_PROJECT: '/projects/edit/:projectId',
  RESOURCE_ALLOCATION: '/resource-allocation',
  RESOURCE_ALLOCATION_OLD: '/resource-allocation-old',
  RESOURCE_ALLOCATION_TESTING: '/resource-allocation-testing',
  QUARTER_VIEW: '/resource-allocation-view',
  SETTINGS: '/settings',
  USERS: '/users',
  PROJECTS: '/projects',
  ROLE: '/Role',
  HOLIDAYS: '/holidays',
  CONFIGURATION: '/configuration',
  ASSETS_TRACKER: '/assets-tracker',
  LISTING: '/listing',
  BOOKINGS: '/bookings',
  VIEW_USER: '/users/:userId',
  USER_RESUME: '/users/:userId/resume',
  FOOD_COUPONS: '/food-coupons',
  UPLOAD_WFH_SHEET: '/upload-wfh-sheet',
  PROFILE: '/profile',
  EMAIL_SIGNATURE: '/email-signature',
  LOGOUT: '/logout',
  STATISTICS: '/statistics',
  LOGS: '/logs',
  ACTIVITY: '/activity',
  HOUR_LOG: '/hour-log',
  HOUR_LOG_SUMMARY: '/hour-log-summary',
  META_REPORTS: '/meta-reports',
  EMPLOYEE_RESUMES: '/employee-resumes',
  EVENTS: '/events',
  EVENT_MANAGEMENT: '/event',
  RSVP_MANAGEMENT: '/rsvp',
  LEAVES: '/leaves',
  INCIDENT_REPORT: '/incident-report',
  PROJECT_RESOURCE_SHEET: '/project-resource-sheet',
  ALERT_CONFIG: '/alert-config',
  INCIDENT_MASTER: '/incident-master',
};

/*  Modules */
export const MODULES = {
  RESOURCE_ALLOCATION: 'Resource Allocation',
  RESOURCE_ALLOCATION_OLD: 'Resource Allocation Old',
  QUARTER_VIEW: 'Quarter View',
  SETTINGS: 'Settings',
  USERS: 'Users',
  PROJECTS: 'Projects',
  ROLE: 'Role',
  HOLIDAYS: 'Holidays',
  CONFIGURATION: 'Configuration',
  ASSETS_TRACKER: 'Assets Tracker',
  LISTING: 'Listing',
  BOOKINGS: 'Bookings',
  FOOD_COUPONS: 'Food Coupons',
  UPLOAD_WFH_SHEET: 'Upload WFH Sheet',
  PROFILE: 'Profile',
  EMAIL_SIGNATURE: 'Email Signature',
  LOGOUT: 'Log Out',
  STATISTICS: 'Statistics',
  LOGS: 'Logs',
  ACTIVITY: 'Activity',
  HOUR_LOG: 'Hour Log History',
  HOUR_LOG_SUMMARY: 'Hour Log Summary',
  EMPLOYEE_RESUMES: 'Employee Resumes',
  EVENTS: 'Events',
  EVENT_MANAGEMENT: 'Event Management',
  RSVP_MANAGEMENT: 'RSVP Management',
  LEAVES: 'Leaves',
  INCIDENT_REPORT: 'Incident Report',
  PROJECT_RESOURCE_SHEET: 'Project Resource Sheet',
  ALERT_CONFIG: 'Team Calendar',
  INCIDENT_MASTER: 'Incident Master',
};

export const MODULES_KEY = {
  ASSETS_BOOKING: 'ASSETS_BOOKING',
  ASSETS_LISTING: 'ASSETS_LISTING',
  CONFIGURATIONS: 'CONFIGURATIONS',
  EMAIL_SIGNATURE: 'EMAIL_SIGNATURE',
  FOOD: 'FOOD',
  HOLIDAYS: 'HOLIDAYS',
  HOURS_LOG_HISTORY: 'HOURS_LOG_HISTORY',
  LOGS: 'LOGS',
  PROJECTS: 'PROJECTS',
  RESOURCE_ALLOCATION: 'RESOURCE_ALLOCATION',
  QUARTER_VIEW: 'QUARTER_VIEW',
  ROLE: 'ROLE',
  SHARED_RESUMES: 'SHARED_RESUMES',
  STATISTICS: 'STATISTICS',
  USERS: 'USERS',
  WFH: 'WFH',
  EVENT_MANAGEMENT: 'EVENTS',
  RSVP_MANAGEMENT: 'EVENTS',
  LEAVES: 'LEAVES',
  INCIDENT_REPORT: 'INCIDENT_REPORT',
  PROJECT_RESOURCE_SHEET: 'PROJECT_RESOURCE_SHEET',
  ALERT_CONFIG: 'ALERT_CONFIG',
  INCIDENT_MASTER: 'INCIDENT_MASTER',
};

export const ROUTES_MODULES_KEY = {
  ASSETS_BOOKING: `${ROUTES?.ASSETS_TRACKER}${ROUTES?.BOOKINGS}`,
  ASSETS_LISTING: `${ROUTES?.ASSETS_TRACKER}${ROUTES?.LISTING}`,
  LOGS: `${ROUTES?.LOGS}${ROUTES?.ACTIVITY}`,
  HOURS_LOG_HISTORY: `${ROUTES?.LOGS}${ROUTES?.HOUR_LOG}`,
  HOUR_LOG_SUMMARY: `${ROUTES?.LOGS}${ROUTES?.HOUR_LOG_SUMMARY}`,
  RESOURCE_ALLOCATION: ROUTES?.RESOURCE_ALLOCATION,
  QUARTER_VIEW: ROUTES?.QUARTER_VIEW,
  EMAIL_SIGNATURE: `${ROUTES?.PROFILE}${ROUTES?.EMAIL_SIGNATURE}`,
  SHARED_RESUMES: ROUTES?.EMPLOYEE_RESUMES,
  STATISTICS: ROUTES?.STATISTICS,
  USERS: `${ROUTES?.SETTINGS}${ROUTES?.USERS}`,
  PROJECTS: `${ROUTES?.SETTINGS}${ROUTES?.PROJECTS}`,
  ROLE: `${ROUTES?.SETTINGS}${ROUTES?.ROLE}`,
  HOLIDAYS: `${ROUTES?.SETTINGS}${ROUTES?.HOLIDAYS}`,
  CONFIGURATIONS: `${ROUTES?.SETTINGS}${ROUTES?.CONFIGURATION}`,
  ALERT_CONFIG: `${ROUTES?.SETTINGS}${ROUTES?.ALERT_CONFIG}`,
  FOOD: ROUTES?.FOOD_COUPONS,
  LEAVES: ROUTES?.LEAVES,
  RESOURCE_ALLOCATION_OLD: ROUTES?.RESOURCE_ALLOCATION_OLD,
  RESOURCE_ALLOCATION_TESTING: ROUTES?.RESOURCE_ALLOCATION_TESTING,
  EVENT_MANAGEMENT: `${ROUTES?.EVENTS}${ROUTES?.EVENT_MANAGEMENT}`,
  RSVP_MANAGEMENT: `${ROUTES?.EVENTS}${ROUTES?.RSVP_MANAGEMENT}`,
  INCIDENT_REPORT: ROUTES?.INCIDENT_REPORT,
  PROJECT_RESOURCE_SHEET: ROUTES?.PROJECT_RESOURCE_SHEET,
  INCIDENT_MASTER: `${ROUTES?.SETTINGS}${ROUTES?.INCIDENT_MASTER}`,
};

export const MODULE_NAMES = [
  { label: 'Users', value: 'USER' },
  { label: 'Projects', value: 'PROJECT' },
  { label: 'Resource Allocation', value: 'RESOURCE_ALLOCATION' },
  { label: 'Holidays', value: 'HOLIDAY' },
  { label: 'Food', value: 'FOOD' },
];

export const USER_TYPE = [
  { label: 'Dedicated', value: 'DEDICATED' },
  { label: 'On Contract', value: 'ON_CONTRACT' },
];

export const BILLING = [
  { label: 'Billable', value: true, text: 'Billable' },
  { label: 'Non-Billable', value: false, text: 'Non-Billable' },
];

export const ACTIONS = [
  { label: 'Create', value: 'CREATE' },
  { label: 'Update', value: 'UPDATE' },
  { label: 'Delete', value: 'DELETE' },
  { label: 'SYNC', value: 'SYNC' },
  { label: 'Error', value: 'ERROR' },
];

export const DEVICE_TYPE = [
  { label: 'Mobile', value: 'MOBILE' },
  { label: 'Tablet', value: 'TABLET' },
  { label: 'Apple TV', value: 'APPLE_TV' },
  { label: 'Roku Stick', value: 'ROKU_STRICK' },
  { label: 'FireTV- Stick ', value: 'FIRE_TV_STRICK' },
];

export const DEVICE_OS = [
  { label: 'iOS', value: 'IOS' },
  { label: 'Android', value: 'ANDROID' },
  { label: 'Roku', value: 'ROKU' },
  { label: 'FireTV ', value: 'FIRE_TV' },
];

export const COUNTRY_OF_ORIGIN = [
  { label: 'India', value: 'INDIA' },
  { label: 'USA', value: 'USA' },
  { label: 'UK', value: 'UK' },
  { label: 'Canada', value: 'CANADA' },
  { label: 'Australia', value: 'AUSTRALIA' },
  { label: 'Japan', value: 'JAPAN' },
  { label: 'China', value: 'CHINA' },
  { label: 'Indonesia', value: 'INDONESIA' },
  { label: 'Brazil', value: 'BRAZIL' },
  { label: 'Pakistan', value: 'PAKISTAN' },
  { label: 'Nigeria', value: 'NIGERIA' },
  { label: 'Bangladesh', value: 'BANGLADESH' },
  { label: 'Russia', value: 'RUSSIA' },
  { label: 'Mexico', value: 'MEXICO' },
];

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';
export const LEVEL = {
  L1: 'L1',
  L2: 'L2',
  L3: 'L3',
  L4: 'L4',
  IN: 'IN',
};
export const ERROR_MESSAGE = 'Your session has expired. Please try again!';
export const SYNC_MESSAGE = 'SYNC started successfully';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const USER_SKILL_SET = [
  { label: 'Languages', value: 'LANGUAGES' },
  { label: 'Tools', value: 'TOOLS' },
  { label: 'Services', value: 'SERVICES' },
  { label: 'Others', value: 'OTHERS' },
];
export const DEVICE_LISTING = [
  { label: 'All', value: 'ALL' },
  { label: 'Pre Assign', value: 'PRE_ASSIGNED' },
  { label: 'Engaged', value: 'ENGAGED' },
  { label: 'Available', value: 'AVAILABLE' },
];

export const SEVERITY = [
  {
    label: 'High',
    value: 'HIGH',
  },
  {
    label: 'Medium',
    value: 'MEDIUM',
  },
  {
    label: 'Low',
    value: 'LOW',
  },
];

export const PERMISSION_OBJECT = {
  read: 'read',
  create: 'create',
  update: 'update',
  delete: 'delete',
  send: 'send',
};

export const ROLES_SORT_FIELD = [{ name: 'Created At', value: 'createdAt' }];
export const WEB = 'WEB';
export const COLUMN_COLOR = '#e8e8e8';
export const PRIMARY_COLOR = '#121d45';
export const ROW_COLOR = {
  APPROVAL_IN_PROCESS: 'APPROVAL_IN_PROCESS',
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
};
export const ROW_HEIGHT = 50;
export const LOGICWIND_URL = 'logicwind.com';

export const BILLING_TYPE = {
  BILLABLE: 'billable',
  NON_BILLABLE: 'non-billable',
};

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin',
  INTERN: 'Intern',
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  USER: 'USER',
  INTERN: 'INTERN',
};

export const EVENT_STATUS = {
  UNPUBLISHED: 'UNPUBLISHED',
  PUBLISHED: 'PUBLISHED',
  PAST: 'PAST',
};

export const INCIDENT_STATUS = {
  PENDING: 'PENDING',
  INITIATED: 'INITIATED',
  RESOLVED: 'RESOLVED',
  REJECTED: 'REJECTED',
};

export const SEVERITY_STATUS = {
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
};

export const SORTING = {
  ASCENDING: 'Ascending',
  DESCENDING: 'Descending',
};

export const EVENT_TYPES = 'Outing';
export const EVENT_CATEGORY = 'Public';
export const HR_DEPARTMENT_DETAILS = {
  HR: 'HR Team',
  TYPE: 'Inquires And Registration',
  MOBILE_NUMBER: '063544 14973',
  EMAIL: 'hr@logicwind.com',
};
export const ATTENDANCE = {
  ATTENDING: 'Attending',
  NOT_ATTENDING: 'Not Attending',
  WAIT_LIST: 'Waitlist',
};

export const EVENT_COUNT = {
  ...ATTENDANCE,
  MAYBE: 'Maybe',
  GUEST: 'Guest',
  NOT_RESPONDED: 'Not Responded',
};

export const EVENT_COLOR = {
  RED: 'red',
  GREEN: 'green',
  YELLOW: 'yellow',
  BLUE: 'blue',
  ORANGE: 'orange',
};

export const PAYMENT_STATUS = {
  PAID: 'Paid',
  UNPAID: 'Unpaid',
  COMP: 'Complete',
};

/* Date and time */
export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm A';
export const LEAVE_DATE_TIME_FORMAT = 'MM/DD/YYYY h:mm a';
export const LEAVE_DATE_FORMAT = 'MM/DD/YYYY';
export const HOUR_LOGS_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
export const BACKEND_MONTH_FORMAT = 'YYYY-MM';
export const BACKEND_YEAR_FORMAT = 'YYYY';
export const WEEK_FORMAT = 'MMM DD';
export const LEAVE_FORMAT = 'MMM DD, YYYY';
export const LEAVE_LABEL_FORMAT = 'Do';
export const CURRENT_YEAR = new Date().getFullYear();
export const DATE_FORMAT = 'DD';
export const WEEK_DAYS_FORMAT = 'ddd';
export const MONTH_FORMAT = 'MMM';
export const LABEL_FORMAT = 'MM/DD';
export const FULL_MONTH_FORMAT = 'MMMM';
export const EXCEL_SHEET_DATE_FORMAT = 'DD-MM-YYYY h:mm A';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
  ONLY_DASH: /^-$/,
  MODEL_NUMBER: /^[0-9A-Z]+$/,
};
export const DISALLOWED_KEYS = [
  'e',
  'E',
  '+',
  '-',
  'ArrowUp',
  'ArrowDown',
  '.',
];

export const PREVIOUS_WEEK_ALLOCATED_HOURS_KEY = 'p';

export const LIMIT = 10;
export const SCROLL_PERCENT = 0.85;

export const UPLOADED_FROM = [
  { name: 'Web', value: 'WEB' },
  { name: 'Mobile', value: 'MOBILE' },
];

export const SORT_ORDER = [
  { name: 'Ascending', value: 'ASC' },
  { name: 'Descending', value: 'DESC' },
];

export const GENDER = [
  { label: 'Male', value: 'MALE' },
  { label: 'Female', value: 'FEMALE' },
  { label: 'Other', value: 'OTHER' },
];

export const SORT_BY = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const LEAVE_TYPE = [
  { label: 'Paid Leave', value: 'PAID_LEAVE' },
  { label: 'Unpaid Leave', value: 'UNPAID_LEAVE' },
];

export const CUSTOM_LEAVE_HALF = [
  { label: 'Full Day', value: 'FULL_DAY' },
  { label: 'Custom', value: 'CUSTOM' },
];

export const LEAVE_HALF_TYPE = [
  { label: 'First Half', value: 'FIRST_HALF' },
  { label: 'Second Half', value: 'SECOND_HALF' },
];

export const LEAVE_HALF = [
  { label: 'Full Day', value: 'FULL_DAY' },
  ...LEAVE_HALF_TYPE,
];

export const STATUS = [
  { text: 'Active', value: 'ACTIVE' },
  { text: 'Inactive', value: 'INACTIVE' },
];

export const CURRENT_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const LEAVE_STATUS = [
  { text: 'Pending', value: 'PENDING' },
  { text: 'Approved', value: 'APPROVED' },
  { text: 'Cancelled', value: 'CANCELLED' },
  { text: 'Rejected', value: 'REJECTED' },
];

export const COLORS = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple',
];

export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};
export const PUSHER_EVENTS = {
  RESOURCE_ALLOCATION: 'resource-allocation',
  RESOURCE_ALLOCATION_UPDATED: 'resource-allocation-updated',
  RESOURCE_ALLOCATION_CREATED: 'resource-allocation-created',
  QUARTERLY_DISTRIBUTION: 'quarterly-distribution',
  QUARTERLY_DISTRIBUTION_EVENT: 'quarterly-distribution-event',
};

export const ALLOW_FILES = ['xls', 'xlsx'];

export const PROJECT_COLUMN_TYPE = {
  CLIENT: {
    name: 'client',
    color: '#6e7f8d',
  },
  PARTNERSHIP: {
    name: 'partnership',
    color: '#6e8762',
  },
  PRODUCT: {
    name: 'product',
    color: '#c9c27b',
  },
  LAB: {
    name: 'lab',
    color: '#6e6e6e',
  },
  CAPPITAL: {
    name: 'cappital',
    color: '#8f6d73',
  },
};

export const ALERT_CONFIG_DAYS_LEAVE_TYPE = {
  PAID: {
    name: 'Paid Leave',
    color: '#64c3d1',
  },
  UNPAID: {
    name: 'Unpaid Leave',
    color: '#c2b89d',
  },
  WORK_FROM_HOME: {
    name: 'Work from home',
    color: '#9785c2',
  },
  WEEKLY_OFF: {
    name: 'Weekly Off',
    color: '#fbc02d',
  },
  HOLIDAY: {
    name: 'Holiday',
    color: '#98b433',
  },
  MULTIPLE_LEAVE_ON_DAY: {
    name: 'Multiple Leave on day',
    color: '#fa5f5f',
  },
};

export const ALERT_CONFIG_LEAVE_TYPE = {
  PAID: 'PAID',
  UNPAID: 'UNPAID',
  FIRST_HALF: 'FIRST_HALF',
  SECOND_HALF: 'SECOND_HALF',
  WORK_FROM_HOME: 'WORK_FROM_HOME',
};

export const CALENDAR_VIEW_MODAL = {
  MONTHS: 'MONTHS',
  THRESHOLD_VALUE: 'THRESHOLD_VALUE',
};

export const DATE_FILTERS = {
  INCIDENT_DATE: 'incidentDateFilter',
  CREATED_AT: 'createdAtFilter',
  UPDATED_AT: 'updatedAtFilter',
};

export const LEARNING_TAG = 'learning';

export const ADD_USER_CHAT_OR_SCRUM_REF_TYPE = {
  COMMON_SPACE_MEMBERS_MANAGEMENT: 'COMMON_SPACE_MEMBERS_MANAGEMENT',
  PROJECT_SPACE_MANAGEMENT: 'PROJECT_SPACE_MANAGEMENT',
  MORNING_CALL_MEMBERS_MANAGEMENT: 'MORNING_CALL_MEMBERS_MANAGEMENT',
};

export const ADD_USER_TO_CHAT_OR_MEET = {
  CHAT: 'chat',
  MEET: 'meet',
};
